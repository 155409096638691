import { UpgradeIconPopover } from "src/components/FeatureBlockers/UpgradeIconPopover"
import {
  TCallAssistUpsellContext,
  usePostCallAssistUpsellClicked,
} from "src/data/analytics/queries/CallAssistAnalyticsQueries"
import { usePostTrackNoiseSettingsCallAssistLearnMoreClickedEvent } from "src/data/analytics/queries/settingsAnalyticsQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"

export type CallAssistUpsellPopupButtonProps = {
  context: TCallAssistUpsellContext | "noise_settings"
}

export function CallAssistUpsellPopupButton({
  context,
}: CallAssistUpsellPopupButtonProps) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const isOrgOwner = getAccessLogic({ role: org.user_role }).hasOwnerAccess
  const error = !isOrgOwner
    ? t(langKeys.subscription_upgrade_blocker_title_non_owner)
    : null

  return (
    <UpgradeIconPopover
      text={t(langKeys.settings_noise_alerts_call_assist_trained_expert)}
      ctaButton={
        <CallAssistUpsellButton
          context={context}
          text={t(langKeys.learn_more)}
          link={Routes.CallAssistActivate.location().pathname}
          disabled={!!error}
        />
      }
      errorMsg={error}
    />
  )
}

type UpgradeBlockerButtonProps = {
  context: CallAssistUpsellPopupButtonProps["context"]
  text: string
  link: string
  disabled?: boolean
}
function CallAssistUpsellButton({
  context,
  text,
  link,
  disabled,
}: UpgradeBlockerButtonProps) {
  const { navigate } = useRouter()
  const postCallAssistLearnMoreClicked =
    usePostTrackNoiseSettingsCallAssistLearnMoreClickedEvent()
  const postCallAssistUpsellClicked = usePostCallAssistUpsellClicked()

  function handleClick() {
    if (context === "noise_settings") {
      postCallAssistLearnMoreClicked.mutate({ context: "upgrade_popup" })
    } else {
      postCallAssistUpsellClicked.mutate({ context })
    }
    navigate(link)
  }

  return (
    <MButton disabled={disabled} onClick={handleClick} size="small">
      {text}
    </MButton>
  )
}

import { TNoiseAlertVariant } from "src/data/profileSettings/noiseAlertTypes"

import { usePostTrackEventWithParams } from "./analyticsQueries"

type TSettingsEvent =
  | "Noise Settings Manage Clicked"
  | "Noise Settings SMS To Guest Toggled"
  | "Noise Settings Autocall Toggled"
  | "Noise Settings Flash And Sound Toggled"
  | "Noise Settings Call Assist Toggled"
  | "Noise Settings SMS To Guest Edit Interacted"
  | "Noise Settings Autocall Edit Interacted"
  | "Noise Settings Manage Dialog Cancelled"
  | "Noise Settings Manage Dialog Saved"
  | "Noise Settings Call Assist Learn More Clicked"

type AlertLevel = "first" | "second" | "third"
type Status = "enabled" | "disabled"

export const variantToAlertLevel: Record<TNoiseAlertVariant, AlertLevel> = {
  first_alert: "first",
  second_alert: "second",
  third_alert: "third",
}

export function booleanToStatus(value: boolean): Status {
  return value ? "enabled" : "disabled"
}

function usePostTrackSettingsEvent<TEventProperties>(event: TSettingsEvent) {
  return usePostTrackEventWithParams<TSettingsEvent, TEventProperties>({
    event,
  })
}
export function usePostTrackNoiseSettingManagedEvent() {
  return usePostTrackSettingsEvent<{ alert_level: AlertLevel }>(
    "Noise Settings Manage Clicked"
  )
}
export function usePostTrackNoiseSettingsSMSToGuestToggledEvent() {
  return usePostTrackSettingsEvent<{
    alert_level: AlertLevel
    new_status: Status
  }>("Noise Settings SMS To Guest Toggled")
}

export function usePostTrackNoiseSettingsAutocallToggledEvent() {
  return usePostTrackSettingsEvent<{
    alert_level: AlertLevel
    new_status: Status
  }>("Noise Settings Autocall Toggled")
}

export function usePostTrackNoiseSettingsFlashAndSoundToggledEvent() {
  return usePostTrackSettingsEvent<{
    alert_level: AlertLevel
    new_status: Status
  }>("Noise Settings Flash And Sound Toggled")
}

export function usePostTrackNoiseSettingsCallAssistToggledEvent() {
  return usePostTrackSettingsEvent<{
    alert_level: "third"
    new_status: Status | "activation"
  }>("Noise Settings Call Assist Toggled")
}

export function usePostTrackNoiseSettingsSMSToGuestEditInteractedEvent() {
  return usePostTrackSettingsEvent<{ alert_level: AlertLevel }>(
    "Noise Settings SMS To Guest Edit Interacted"
  )
}

export function usePostTrackNoiseSettingsAutocallEditInteractedEvent() {
  return usePostTrackSettingsEvent<{ alert_level: AlertLevel }>(
    "Noise Settings Autocall Edit Interacted"
  )
}

export function usePostTrackNoiseSettingsManageDialogCancelledEvent() {
  return usePostTrackSettingsEvent<{ alert_level: AlertLevel }>(
    "Noise Settings Manage Dialog Cancelled"
  )
}

export function usePostTrackNoiseSettingsManageDialogSavedEvent() {
  return usePostTrackSettingsEvent<{ alert_level: AlertLevel }>(
    "Noise Settings Manage Dialog Saved"
  )
}

export function usePostTrackNoiseSettingsCallAssistLearnMoreClickedEvent() {
  return usePostTrackSettingsEvent<{
    context: "third_alert_card" | "upgrade_popup"
  }>("Noise Settings Call Assist Learn More Clicked")
}
